import { Card, Pagination, ProgressIndicator, useToast } from '@veneer/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { StateContext } from '../../store';
import styles from '../JobQueuePanel/JobQueuePanel.module.scss';
import JobsTable from '../JobQueuePanel/JobsTable';

import useJobHistoryApiCall from 'src/hooks/useJobHistoryApiCall';
import { actionFetchJobHistoryApi } from 'src/store/action-creators';
import './JobHistoryPanel.css';

const JobHistoryPanel = ({
  printersNamesMap,
  stratusTenantId,
  stratusResourceId
}) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {};
  const [jobHistory, setJobHistory] = useState([]);
  const [historyPerPage, setHistoryPerPage] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    pageSize: 5
  });
  const [totalJobs, setTotalJobs] = useState(0);
  const {
    state: {
      user: { nonOwner },
      jobHistoryFetch: { shouldFetchJobHistoryApi }
    },
    dispatch
  } = useContext(StateContext);
  const { addToast } = useToast();
  const {
    loading: jobHistoryApiLoading,
    data: jobHistoryApiData,
    error: jobHistoryError,
    makeApiCall: refreshJobHistoryApiCall
  } = useJobHistoryApiCall(stratusTenantId, stratusResourceId, nonOwner) || {
    loading: true,
    data: {}
  };

  useEffect(() => {
    if (shouldFetchJobHistoryApi) {
      setTimeout(() => {
        refreshJobHistoryApiCall();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchJobHistoryApi]);

  useEffect(
    () => {
      setLoading(jobHistoryApiLoading);
      if (
        !jobHistoryApiLoading &&
        jobHistoryApiData?.printJobs &&
        printersNamesMap
      ) {
        let jobsArray = [];
        if (jobHistoryApiData.printJobs.length > 0) {
          jobHistoryApiData.printJobs.forEach((printerJobs) => {
            printerJobs.jobs.forEach((job) => {
              job['printerName'] = printersNamesMap[printerJobs.deviceId];
              job['timeValue'] = moment(job.jobCompletionTime).valueOf();
              job['jobPrinterId'] = printerJobs.deviceId;
            });
            jobsArray.push(...printerJobs.jobs);
          });
        }
        const terminalJobs = jobsArray.filter(
          ({ status }) =>
            status === 'cancelled' ||
            status === 'success' ||
            status === 'failed'
        );
        terminalJobs.sort((a, b) => b.timeValue - a.timeValue);
        if (terminalJobs.length > 0) {
          setJobHistory(terminalJobs);
          setTotalJobs(terminalJobs.length);
        }
        if (terminalJobs.length > pagination.pageSize) {
          setHistoryPerPage(terminalJobs.slice(0, 5));
        } else {
          setHistoryPerPage(terminalJobs);
        }
      } else if (jobHistoryError) {
        addToast({
          type: 'negative',
          id: 'printAnywhere-jobHistory-fetchJobHistory-error-toast',
          text: t('jobHistory.title', ''),
          subtitle: t('jobHistory.error', '')
        });
        throw new Error(jobHistoryError);
      }
      if (!jobHistoryApiLoading) {
        dispatch(actionFetchJobHistoryApi(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobHistoryApiData, jobHistoryApiLoading, jobHistoryError, printersNamesMap]
  );
  return (
    <React.Fragment>
      {loading && jobHistory.length === 0 ? (
        <ProgressIndicator
          data-testid="potg-component-spinner"
          className="potg_components__spinner"
          appearance="circular"
          size={48}
        />
      ) : null}
      {jobHistory.length > 0 && stratusResourceId && stratusTenantId ? (
        <>
          <div
            data-testid="potg-job-history-panel"
            className={styles.jobqueue_panel}
          >
            <Card
              className={styles.jobqueue_panel__card}
              appearance="dropShadow"
              style={{ borderWidth: 0 }}
              content={
                <>
                  <div className={styles.jobqueue_panel__header}>
                    <h5 className={styles.jobqueue_panel__title}>
                      {`${t('jobHistory.title', '')} (${jobHistory.length})`}
                    </h5>
                    {t('jobHistory.description', '')}
                  </div>
                  <JobsTable
                    jobs={historyPerPage}
                    isHistoryTable={true}
                  />
                </>
              }
            />
            {totalJobs > pagination.pageSize ? (
              <div
                data-testid="potg-job-history-pagination-wrapper"
                className={styles.jobqueue__pagination_container}
              >
                <Pagination
                  className={styles.jobqueue__pagination}
                  currentPage={pagination.currentPage}
                  pageSize={pagination.pageSize}
                  onPageChange={(page) => {
                    setPagination((pagination) => ({
                      ...pagination,
                      pageNumber: page
                    }));
                    setHistoryPerPage(jobHistory.slice(page * 5 - 5, page * 5));
                  }}
                  totalItems={totalJobs}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
};

JobHistoryPanel.propTypes = {
  printersNamesMap: PropTypes.object,
  stratusTenantId: PropTypes.string,
  stratusResourceId: PropTypes.string
};

JobHistoryPanel.defaultProps = {
  printersNamesMap: {},
  stratusTenantId: '',
  stratusResourceId: ''
};
export default JobHistoryPanel;
